import {NgIf} from '@angular/common';
import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {RouterOutlet} from '@angular/router';
import {FuseLoadingBarComponent} from '@fuse/components/loading-bar';
import {FuseNavigationItem, FuseNavigationService, FuseVerticalNavigationComponent} from '@fuse/components/navigation';
import {FuseMediaWatcherService} from '@fuse/services/media-watcher';
import {NavigationService} from 'app/core/navigation/navigation.service';
import {Navigation} from 'app/core/navigation/navigation.types';
import {UserComponent} from 'app/layout/common/user/user.component';
import {combineLatest, map, Subject, takeUntil} from 'rxjs';
import {SecurityService} from "../../../../core/auth/security.service";
import {TicketService} from "../../../../core/helpdesk/ticket.service";
import {CircularAvatarComponent} from "../../../../components/circular-avatar/circular-avatar.component";

@Component({
    selector: 'classic-layout',
    templateUrl: './classic.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [FuseLoadingBarComponent, FuseVerticalNavigationComponent, MatButtonModule, MatIconModule, UserComponent, NgIf, RouterOutlet, CircularAvatarComponent],
})
export class ClassicLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;

    navigation: Navigation;
    @ViewChild(FuseVerticalNavigationComponent) verticalNavigationComponent: FuseVerticalNavigationComponent;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _ticketService: TicketService,
        public _securityService: SecurityService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.menu()
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
                this.mainNavigation?.refresh();
            })

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    get mainNavigation() {
        return this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');
    }

    private menu() {
        return combineLatest([this._ticketService.pendingCount$, this._navigationService.navigation$])
            .pipe(
                takeUntil(this._unsubscribeAll),
                map(([count, menuItems]) => {
                    if (count > 0) {
                        let items: FuseNavigationItem[] = menuItems.default;

                        let helpdeskMain = items.filter(item => item.id === 'helpdesk.main');

                        if (helpdeskMain.length > 0) {
                            let mainMenuElement = helpdeskMain[0];

                            let filter = mainMenuElement.children?.filter(item => item.id === 'helpdesk.ticket.list');

                            if (filter?.length > 0) {
                               mainMenuElement = filter[0];
                            }

                            mainMenuElement.badge = {
                                title: String(count),
                                classes: "px-2 bg-pink-600 text-white rounded-full"
                            }


                        }


                    }

                    return menuItems;
                })
            );
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
