import {Injectable} from '@angular/core';
import {User} from "../user/user.types";
import {UserRole} from "../common-types";

@Injectable({
    providedIn: 'root'
})
export class SecurityService {

    public isUser: boolean;
    public isAdmin: boolean;
    public isHelpdesk: boolean;
    public isHelpdeskOrAdmin: boolean;
    public isTalentOrAdmin: boolean;
    public isManager: boolean;
    public isTalent: boolean;
    public isFinance: boolean;
    public isFinanceOrAdmin: boolean;
    public isHelpdeskOrAdminOrManager: boolean;
    public currentUser: User;

    constructor() {
        this.load();
    }

    public load(): void {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        this.isUser = this.hasRole(UserRole.USER);
        this.isAdmin = this.hasRole(UserRole.ADMIN);
        this.isHelpdesk = this.hasRole(UserRole.HELPDESK);
        this.isManager = this.hasRole(UserRole.PROJECT_MANAGER);
        this.isTalent = this.hasRole(UserRole.TALENT);
        this.isFinance = this.hasRole(UserRole.FINANCE);
        this.isFinanceOrAdmin = this.isAdmin || this.isFinance;
        this.isHelpdeskOrAdmin = this.isAdmin || this.isHelpdesk;
        this.isTalentOrAdmin = this.isAdmin || this.isTalent;
        this.isHelpdeskOrAdminOrManager = this.isAdmin || this.isHelpdesk || this.isManager;
    }

    public hasRole(role: UserRole): boolean {
        return this.currentUser?.roles.includes(role.toString());
    }

    public hasAnyRole(roles: UserRole[]): boolean {
        return roles.some(role => this.hasRole(role));
    }

    public hasGroup(group: string): boolean {
        return this.currentUser.groups.includes(group);
    }

    public isMe(id: number): boolean {
        return this.currentUser.id === id;
    }

}
