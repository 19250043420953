import {HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest} from '@angular/common/http';
import {inject} from '@angular/core';
import {AuthService} from 'app/core/auth/auth.service';
import {AuthUtils} from 'app/core/auth/auth.utils';
import {catchError, Observable, throwError} from 'rxjs';
import {ApiError} from "../common-types";
import {SnackbarService} from "../../components/snackbar-icon/snackbar-service";
import {Router} from "@angular/router";

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const authService = inject(AuthService);
    const snackBar = inject(SnackbarService);
    const router = inject(Router);

    // Clone the request object
    let newReq = req.clone();

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    if (authService.accessToken && !AuthUtils.isTokenExpired(authService.accessToken)) {
        newReq = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + authService.accessToken),
        });
    }

    // Response
    return next(newReq).pipe(
        catchError((error) => {
            // Catch "401 Unauthorized" responses
            if (error instanceof HttpErrorResponse) {

                if (error.status === 401 && !error.error.path.startsWith('/auth')) {
                    // Sign out
                    authService.signOut();

                    // Reload the app
                    location.reload();
                } if (error.status === 403) {
                    // Redirect to 403 page
                    router.navigate(['/error-403']).then();

                } else if (!error.error.path.startsWith('/auth')) {
                    let apiError = error.error as ApiError;

                    snackBar.error(apiError.errors[0].message);
                }
            }

            return throwError(() => error);
        }),
    );
};
