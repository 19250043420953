import {Route} from '@angular/router';
import {AuthGuard} from 'app/core/auth/guards/auth.guard';
import {NoAuthGuard} from 'app/core/auth/guards/noAuth.guard';
import {LayoutComponent} from 'app/layout/layout.component';
import {initialDataResolver} from "./app.resolvers";

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    {path: '', pathMatch : 'full', redirectTo: 'people'},

    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'helpdesk/ticket/list'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes')},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes')},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes')},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'complete-public-info', loadChildren: () => import('app/modules/auth/complete-public-information/complete-public-information.routes')},
            {path: 'complete-onboarding', loadChildren: () => import('app/modules/auth/complete-onboarding/complete-onboarding.routes')},
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes')}
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes')},
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            {path: 'helpdesk/dashboard', loadChildren: () => import('app/modules/helpdesk/dashboard/dashboard.routes')},
            {path: 'helpdesk/ticket', loadChildren: () => import('app/modules/helpdesk/ticket/ticket.routes')},
            {path: 'people', loadChildren: () => import('app/modules/user/user.routes')},
            {path: 'profile', loadChildren: () => import('app/modules/profile/profile.routes')},
            {path: 'talent', loadChildren: () => import('app/modules/talent/talent.routes')},
            {path: 'drive', loadChildren: () => import('app/modules/drive/drive.routes')},
            {path: 'inventory', loadChildren: () => import('app/modules/inventory/inventory.routes')},
            {path: 'finance/invoice', loadChildren: () => import('app/modules/finance/invoice/invoice.routes')},
            {path: 'finance/client', loadChildren: () => import('app/modules/finance/client/client.routes')},
            {path: 'finance/job-number', loadChildren: () => import('app/modules/finance/job-number/job-number.routes')},
            {path: 'error-403', loadChildren: () => import('app/modules/errors/error-403/error-403.routes')},
        ]
    }
];
