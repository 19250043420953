import {inject, Injectable} from "@angular/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SnackbarIconComponent} from "./snackbar-icon.component";

@Injectable({
    providedIn: 'root'
})
export class SnackbarService {

    private _snackbar = inject(MatSnackBar);

    public ok(message: string) {
        this._snackbar.openFromComponent(SnackbarIconComponent, {
            data: {
                icon: 'heroicons_outline:check',
                iconColor: '',
                message: message
            },
            duration: 5000
        });
    }

    public error(message: string) {
        this._snackbar.openFromComponent(SnackbarIconComponent, {
            data: {
                icon: 'heroicons_outline:x-mark',
                iconColor: 'text-red-500',
                message: message
            },
            duration: 5000
        });
    }

}
