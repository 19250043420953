import {Component, Inject} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MAT_SNACK_BAR_DATA} from "@angular/material/snack-bar";
import {MatIconModule} from "@angular/material/icon";

@Component({
  selector: 'app-snackbar-icon',
  standalone: true,
    imports: [CommonModule, MatIconModule],
  template: `<div class="flex justify-start items-center gap-1"><mat-icon [class]="data?.iconColor || 'text-green-500'" [svgIcon]="data?.icon"></mat-icon> <span>{{ data?.message }}</span></div>`
})
export class SnackbarIconComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }
}
