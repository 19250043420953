export class Pagination {
    length: number = 0;
    pageSize: number = 25;
    pageIndex: number = 0;
}

export interface ApiErrorDetail {
    field: string;
    type: string;
    message: string;
    messageKey: string;
}

export interface ApiError {
    path: string;
    method: string;
    date: string;
    errors: ApiErrorDetail[];
}

export class Role {
    id: number;
    name: string
}

export interface SortCommand {
    field: string;
    direction: string;
}

export interface Page {
    number: number;
    size: number;
    totalElements: number;
    totalPages: number;
}

export interface UserGroupPermission {
    id: number;
    name: string;
}


export enum UserRole {
    ADMIN = "ADMIN",
    HELPDESK = "HELPDESK",
    USER = "USER",
    PROJECT_MANAGER = "PROJECT_MANAGER",
    TALENT = "TALENT",
    FINANCE = "FINANCE",
    ACCOUNT_SUPERVISOR = "ACCOUNT_SUPERVISOR",
    ACCOUNT_MANAGER = "ACCOUNT_MANAGER",
    ACCOUNT_DIRECTOR = "ACCOUNT_DIRECTOR",
    VENDOR = "VENDOR",
}

export interface CreateLocationCommand {
    addressOne: string;
    addressTwo: string;
    city: string;
    state: string;
    postalCode: string;
    countryId: number;
}
